import * as React from "react"
import { graphql } from 'gatsby'

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// COmponents
import { Page} from 'components/core/layouts/base';
import { Hero } from 'components/privacy-policy/hero';
import { Content } from 'components/privacy-policy/content';
import { Footer } from 'components/core/footer';

function IndexPage({location}){
  const {t} = useTranslation("privacy-policy");
  return <Page dark={false} location={location} title={t("metadata.title")} description={t("metadata.description")}>
          <Hero/>
          <Content/>
          <Footer/>
        </Page>
}

export default IndexPage;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;